<template>
    <div>

        <b-container
            v-if="images.length"
            fluid
            class="p-1 bg-dark mb-2"
        >
            <b-row>
                <b-col md="2" v-for="(image, key) in images" :key="key">
                    <b-img
                        thumbnail
                        fluid
                        :src="transformImg(image)"
                        :alt="`Imagen ${key+1}`"
                    />
                    <b-button 
                        variant="outline-danger"
                        @click="deleteImage(image)"
                        block>
                        ELIMINAR
                    </b-button>
                </b-col>
                
            </b-row>
        </b-container>

        <b-row>
            <b-col md="12" class="mb-2">	
                <vue-dropzone  
                    ref="myVueDropzoneRef"
                    id="myVueDropzone"
                    @vdropzone-success-multiple="vsuccess"
                    @vdropzone-error="verror"
                    :options="dropzoneOptions"
                    :headers="headers"
                    :useCustomSlot="true"		
                >
                    <div class="dropzone-custom-content">
                        <h4 class="dropzone-custom-title"><strong>¡Arrastre o haga click aquí para cargar las imágenes!</strong></h4>
                        <div class="subtitle">formato: webp</div>
                    </div>
                </vue-dropzone>       
            </b-col>
            <b-col>                
                <b-alert  variant="info" :show="true">
                    <div class="alert-body">
                        <ul class="mb-0">
                            <li>
                                Comprime y optimiza tus imágenes en 
                                <b-link
                                    class="alert-link"
                                    href="https://tinypng.com/"
                                    target="_blank"
                                >
                                    Tinify
                                </b-link>
                            </li>
                            <li>
                                Convierte tus imágenes a .webp en 
                                <b-link
                                    class="alert-link"
                                    href="https://pixelied.com/convert/jpg-converter"
                                    target="_blank"
                                >
                                    Pixelied
                                </b-link>
                            </li>
                        </ul>
                    </div>
                </b-alert>
            </b-col>
        </b-row>

        <!-- Form: Info Form -->
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>

                    <!-- Product Name -->
                    <b-col
                        cols="12"
                        md="8"
                    >							
                        <b-form-group
                            label="Nombre del producto"
                            label-for="name"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="nombre del producto"
                            >
                                <b-form-input
                                    id="name"
                                    v-model="productData.name"
                                    placeholder="Nombre del producto ..."
                                    :state="errors.length > 0 ? false : null"
                                    trim
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    
                    <!-- Product Stock -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Stock"
                            label-for="stock"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="stock del producto"
                            >
                                <b-form-input
                                    id="stock"
                                    v-model="productData.stock"
                                    placeholder="Stock del producto ..."
                                    :state="errors.length > 0 ? false : null"
                                    class="form-control"
                                    trim
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Product Type -->
                    <b-col md="6">
                        <b-form-group
                            label="Seleccionar tipo de producto"
                            label-for="product_type">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="tipo de producto"
                            >
                                <v-select
                                    id="product_type"
                                    v-model="productData.product_type"
                                    :state="errors.length > 0 ? false:null"
                                    :options="product_types"
                                    label="name"
                                />

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Category -->
                    <b-col md="6">
                        <b-form-group
                            label="Seleccionar categoría"
                            label-for="category">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="categoría"
                            >
                                <v-select
                                    id="category"
                                    v-model="productData.category"
                                    :state="errors.length > 0 ? false:null"
                                    :options="categories"
                                    label="name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Brand -->
                    <b-col md="6">
                        <b-form-group
                            label="Seleccionar marca"
                            label-for="brand">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="marca"
                            >
                                <v-select
                                    id="brand"
                                    v-model="productData.brand"
                                    :state="errors.length > 0 ? false:null"
                                    :options="brands"
                                    label="name"
                                />

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Tags -->
                    <b-col md="6">
                        <b-form-group
                            label="Seleccionar etiqueta(s)"
                            label-for="tags">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="tag(s)"
                            >
                                <v-select
                                    id="tags"
                                    v-model="productData.tag"
                                    :state="errors.length > 0 ? false:null"
                                    :options="tags"
                                    multiple
                                    label="name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Description -->
                    <b-col md="12">
                        <b-form-group
                            label="Descripción"
                            label-for="description">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="descripción"
                            >
                            
                                <quill-editor
                                    v-model="productData.description"
                                    :options="snowOption"
                                    :state="(errors.length > 0 ? false:null)"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Description SEO -->
                    <b-col md="12">
                        <b-form-group
                            label="Descripción (SEO)"
                            label-for="description_seo">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="descripción (SEO)"
                            >
                                <b-form-textarea
                                    id="description_seo"
                                    v-model="productData.description_seo"
                                    placeholder="Descripción (SEO) ..."
                                    rows="3"
                                    :state="(errors.length > 0 ? false:null)"
                                    class="char-textarea"
                                />
                                <small
                                    class="textarea-counter-value float-right"
                                >
                                    <span class="char-count">Caracteres: {{ productData.description_seo ? productData.description_seo.length : 0 }}</span>
                                </small>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    
                    <!-- Discount Type -->
                    <b-col md="6">
                        <b-form-group
                            label="Seleccionar tipo de descuento"
                            label-for="discount_type">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="tipo de descuento"
                            >
                                <v-select
                                    id="discount_type"
                                    v-model="productData.discount"
                                    :options="discount_types"
                                    :state="errors.length > 0 ? false:null"
                                    label="type"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Product Discount (Amount or Percent) -->
                    <b-col
                        cols="6"
                    >
                        <b-form-group
                            v-if="productData.discount && productData.discount.type != 'Ninguno'"
                            :label="productData.discount ? productData.discount.type == 'Soles' ? 'Monto' : 'Porcentaje' : 'Monto'"
                            label-for="discount_amount"
                        >
                            <b-input-group
                                :prepend="productData.discount ? productData.discount.type == 'Soles' ? 'S/. ' : '% ' : 'S/. '"
                                class="input-group-merge"
                            >
                                <b-form-input
                                    id="discount_amount"
                                    v-model="productData.discountAmount"
                                    class="form-control"
                                    :disabled="productData.discount ? false : true"
                                    trim
                                />
                            </b-input-group>		
                        </b-form-group>
                    </b-col>

                    <!-- The product have variants? -->
                    <b-col
                        cols="12"
                        md="12"
                    >
                        <b-row>

                            <!-- Have Variants -->
                            <b-col
                                cols="6"
                                md="6"
                            >
                                <b-form-group
                                    label="¿Tiene variantes?"
                                    label-for="variants"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="tiene variantes"
                                    >
                                        <v-select
                                            id="variants"
                                            v-model="variant"
                                            :options="variantOptions"
                                            :state="errors.length > 0 ? false:null"
                                            label="label"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>		
                                </b-form-group>
                            </b-col>

                            <!-- Product Price -->
                            <b-col
                                cols="3"
                                md="3"
                                v-if="variant && variant.label == 'NO'"
                            >
                                <b-form-group
                                    label="Precio"
                                    label-for="price_init"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="precio del producto"
                                    >
                                        <b-input-group
                                            prepend="S/. "
                                            class="input-group-merge"
                                        >
                                            <b-form-input
                                                id="price_init"
                                                v-model="productData.price_init"
                                                placeholder="Precio del producto ..."
                                                :state="errors.length > 0 ? false : null"
                                                class="form-control"
                                                trim
                                            />						
                                        </b-input-group>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Have Sub Variants -->
                            <b-col
                                cols="3"
                                md="3"
                                v-if="variant && variant.label == 'SÍ'"
                            >
                                <b-form-group
                                    label="¿Tiene sub-variantes?"
                                    label-for="subvariants"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="tiene sub-variantes"
                                    >
                                        <v-select
                                            id="subvariants"
                                            v-model="subvariant"
                                            :options="variantOptions"
                                            :state="errors.length > 0 ? false:null"
                                            label="label"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>		
                                </b-form-group>
                            </b-col>

                        </b-row>

                        <b-row v-if="variant && variant.label == 'SÍ'">
                            <!-- Add Variant Button -->
                            <b-col md="12">
                                <b-button
                                    @click="addVariant"
                                    size="sm"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                >
                                    AGREGAR VARIANTE
                                </b-button>
                                <hr>
                                <template v-if="subvariant &&subvariant.label =='NO'">
                                    <div v-for="(item, index) in variants" :key="item.id">
                                        <b-input-group prepend="Variante" :class="{ 'mb-2': (index+1 != variants.length) }">
                                            <b-form-input v-model="item.name" placeholder="Nombre de la variante ..."></b-form-input>
                                            <b-form-input v-model="item.price" placeholder="Precio de la variante ..."></b-form-input>
                                            <b-input-group-append>
                                                <b-button size="sm" variant="danger" @click="removeVariant(item.id)">
                                                    <feather-icon icon="Trash2Icon" />
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </template>
                                <template v-if="subvariant &&subvariant.label =='SÍ'">
                                    <div v-for="(variant, index) in variants" :key="variant.id">
                                        <b-input-group prepend="Variante" :class="{ 'mb-2': (variant.subvariants && variant.subvariants.length) }">
                                            <b-form-input v-model="variant.name" placeholder="Nombre de la variante ..."></b-form-input>
                                            <b-input-group-append>
                                                <b-button size="sm" variant="success" @click="addSubVariant(variant.id)">
                                                    AGREGAR SUB-VARIANTE
                                                </b-button>
                                                <b-button size="sm" variant="danger" @click="removeVariant(variant.id)">
                                                    <feather-icon icon="Trash2Icon" />
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <div v-for="subvariant in variant.subvariants" :key="subvariant.id">
                                            <b-input-group prepend="Sub-Variante">
                                                <b-form-input v-model="subvariant.name" placeholder="Nombre de la sub-variante ..."></b-form-input>
                                                <b-form-input v-model="subvariant.price" placeholder="Precio de la sub-variante ..."></b-form-input>
                                                <b-input-group-append>
                                                    <b-button size="sm" variant="danger" @click="removeSubVariant(variant.id, subvariant.id)">
                                                        <feather-icon icon="Trash2Icon" />
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </div>
                                        <hr v-if="(index+1 != variants.length)">
                                    </div>
                                </template>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </b-form>
        </validation-observer>
    
        <!-- Action Buttons -->
        <b-button
            @click="updateInformation"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
            ACTUALIZAR
        </b-button>
    </div>
</template>

<script>

    import { BButtonGroup, BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BFormCheckbox, BAlert } from 'bootstrap-vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import "vue2-dropzone/dist/vue2Dropzone.min.css";
    import Ripple from 'vue-ripple-directive';
    import vue2Dropzone from 'vue2-dropzone';
    import { required } from '@validations';
    import Toast from 'vue-toastification';
    import { v4 as uuidv4 } from 'uuid';
	import { quillEditor } from 'vue-quill-editor'
    import vSelect from 'vue-select';
    import Swal from 'sweetalert2';
    import axios from 'axios';
    import Vue from 'vue';

    Vue.use(Toast);

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            vueDropzone: vue2Dropzone,
            quillEditor,
            BButtonGroup,
            BButton,
            BMedia,
            BAvatar,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BFormTextarea,
            BForm,
            BAlert,
            vSelect,

            required
        },
        props: {
            productData: {
                type: Object,
                required: true
            }
        },
        data (){
            return {
                images: [],
                product_types: [],
                categories: [],
                brands: [],
                tags: [],
                discount_types: [],
                snowOption: {
					theme: 'snow',
				},	

                variant: null,
                subvariant: null,
                variants: [],
                variantOptions: [{
					_id: 1,
					label: 'SÍ'
				}, {
					_id: 0,
					label: 'NO'
				}],

                headers: { 'Content-Type': 'multipart/form-data' },
                dropzoneOptions: {
                    url: `${process.env.VUE_APP_HOST}/api/dashboard/product/${this.productData._id}/update/files`,
                    method: 'put',
                    paramName: this.paramNameForSend,
                    autoDiscover: false,
                    maxFiles: 10,
                    maxThumbnailFilesize: 10,
                    parallelUploads: 10,
                    acceptedFiles: ".webp,",
                    addRemoveLinks: false,		
                    autoProcessQueue: true,					
                    thumbnailWidth: 140,
                    thumbnailHeight: 140,
                    dictRemoveFile: "Eliminar",
                    uploadMultiple: true
                }
            }
        },
        directives: {
			Ripple
		},
        watch: {
            'productData.discount' (){
                this.productData.discountAmount = 0;
            },
			variant (newValue){
				this.variants = [];
				if (newValue && newValue._id == 1){
                    if (this.productData.variants && this.productData.variants.length){
                        if (this.productData.isSubVariant){

                            this.productData.variants.map(variant => {

                                let subvariants = [];

                                if (variant.subVariants && variant.subVariants.length){
                                    variant.subVariants.map(subvariant => {
                                        subvariants.push({
                                            id: subvariant.id,
                                            name: subvariant.name,
                                            price: subvariant.price
                                        });
                                    });
                                }

                                this.variants.push({
                                    id: variant.id,
                                    name: variant.name,
                                    subvariants
                                });

                            });

                        } else {

                            this.productData.variants.map(variant => {
                                this.variants.push({
                                    id: variant.id,
                                    name: variant.name,
                                    price: variant.price_init
                                });
                            });

                        }
                    } else {
                        if (this.productData.isSubVariant){

                            this.variants.push({
                                id: uuidv4(),
                                name: null,
                                subvariants: []
                            });

                        } else {

                            this.variants.push({
                                id: uuidv4(),
                                name: null,
                                price: null
                            });

                        }
                    }
				} else {
                    this.subvariant = null;
                }
			}
        },
        async beforeMount (){
            await this.getCategories();
            await this.getBrands();
            await this.getProductTypes();
            await this.getTags();
            await this.getDiscountTypes();
            await this.getProductImages();

            if (this.productData.isVariant){
                this.variant = this.variantOptions.find(i => i._id == 1);
                if (this.productData.isSubVariant){
                    this.subvariant = this.variantOptions.find(i => i._id == 1);
                } else {
                    this.subvariant = this.variantOptions.find(i => i._id == 0);
                }
            } else {
                this.variant = this.variantOptions.find(i => i._id == 0);
                this.subvariant = null;
            }
        },
        methods: {
            paramNameForSend(){
                return "images";
            },
            transformImg (img){
                if (img){
                    if (img.includes('http') || img.includes('https')){
                        return img;
                    } else {
                        return require(`@/assets/images/modules/products/${img}`);
                    }
                } else {
                    return null;
                }
            },
            async getProductImages (){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/product/${this.productData._id}/images`);
                this.images = response.data;
            },
            async getCategories(){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/categories`);
                this.categories = response.data;
            },
            async getBrands(){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/brands`);
                this.brands = response.data;
            },
            async getProductTypes(){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/product-types`);
                this.product_types = response.data;
            },
            async getTags(){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/tags`);
                this.tags = response.data;
            },
            async getDiscountTypes(){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/discounts`);
                this.discount_types = response.data;
            },
            async deleteImage (image){
                let response = await axios.post(`${process.env.VUE_APP_HOST}/api/dashboard/product/${this.productData._id}/delete/image`, { image });
                this.images = response.data.images;
                Swal.fire({
                    title: '¡Eliminado!',
                    text: response.data.message,
                    timer: 2500,
                    icon: 'success',
                    showConfirmButton: false
                });
            },
            vsuccess(files, response){
                setTimeout( () => {
                    this.images = response.images;
                }, 3000);
                Swal.fire({
                    title: '¡Actualizado!',
                    text: response.message,
                    timer: 3500,
                    icon: 'success',
                    showConfirmButton: false
                });
            },
            verror(file, message, xhr){
                console.error(message)
            },
            addVariant (){
                if (this.subvariant._id == 1){

                    this.variants.push({
                        id: uuidv4(),
                        name: null,
                        subvariants: []
                    });

                } else {

                    this.variants.push({
                        id: uuidv4(),
                        name: null,
                        price: null
                    });

                }
			},
            addSubVariant (variantId){

                const variant = this.variants.find(variant => variant.id === variantId);

                variant.subvariants.push({
                    id: uuidv4(),
                    name: null,
                    price: null
                });

            },
			removeVariant (id){
				this.variants = this.variants.filter(i => i.id != id);
			},
            removeSubVariant (variantId, subVariantId){
				const variant = this.variants.find(variant => variant.id === variantId);

				variant.subvariants = variant.subvariants.filter(subvariant => subvariant.id != subVariantId);
			},
			validateVariants (){
				let errors = false;

				this.variants.map(variant => {
					if (!variant.name || variant.name == '' || variant.name == ' ' || !variant.price){
						errors = true;
					}
				});

				return errors;
			},
            updateInformation (){
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {

                        if (this.subvariant && this.subvariant._id){

                            let error, error2, error3 = false;
                            let notSubVariants = [];

                            if ((this.variant._id && !this.variants.length)){
                                error = true;
                            }

                            if (!error){
                                this.variants.map((variant, index) => {
                                    if (!variant.subvariants.length){
                                        error2 = true;
                                        notSubVariants.push((index + 1));
                                    }
                                    if (!variant.name || variant.name == '' || variant.name == ' '){
                                        error3 = true;
                                    }
                                    variant.subvariants.map(subvariant => {
                                        if (!subvariant.name || subvariant.name == '' || subvariant.name == ' ' || !subvariant.price){
                                            error3 = true;
                                        }
                                    });
                                });
                            }

                            if(error || error2 || error3) {
                                let message = null;

                                if (error) {
                                    message = '¡Debe cargar al menos una variante!';
                                } else if (error2){
                                    message = `¡Debe cargar al menos una sub-variante para las variantes: ${notSubVariants.join(', ')}!`;
                                } else if (error3){
                                    message = '¡Debe completar toda la información de las variantes y subvariantes cargadas!'
                                }

                                Swal.fire({
                                    title: 'Error',
                                    text: message,
                                    icon: 'error',
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: false,
                                    showConfirmButton: false
                                });
                                
                            } else {
                                this.updateInformationLogic();
                            }

                        } else {

                            let error = false;

                            const validateVariants = this.validateVariants();

                            if ((this.variant._id && !this.variants.length) || validateVariants) {
                                error = true;
                            }
                            
                            if(error) {
                                let message = validateVariants ? '¡Debe completar toda la información de las variantes cargadas!' : '¡Debe cargar al menos una variante!';

                                Swal.fire({
                                    title: 'Error',
                                    text: message,
                                    icon: 'error',
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: false,
                                    showConfirmButton: false
                                });
                            } else {
                                this.updateInformationLogic();
                            }

                        }

                    }
                });
            },
            updateInformationLogic (){
                const tagArr = this.productData.tag.map(item => item._id);

                // const variantIds = this.variants.map(item => item.id);
                // const variantNames = this.variants.map(item => item.name);
                // const variantPrices = this.variants.map(item => item.price);

                let priceUnit = this.productData.price_init;

				if (this.variant._id){
					if (this.subvariant._id){
						priceUnit = this.variants[0].subvariants[0].price;
					} else {
						priceUnit = this.variants[0].price;
					}
				}

                let data = {
                    name: this.productData.name,
                    description: this.productData.description,
                    description_seo: this.productData.description_seo,
                    isVariant: (this.variant._id ? true : false),
                    isSubVariant: (this.subvariant ? (this.subvariant._id ? true : false) : false),
                    price_init: priceUnit,
                    stock: this.productData.stock,
                    variants: JSON.stringify(this.variants),
                    product_type: this.productData.product_type._id,
                    category: this.productData.category._id,
                    brand: this.productData.brand._id,
                    discount: this.productData.discount._id,
                    discountAmount: this.productData.discountAmount,
                    tag: tagArr
                };

                axios.put(`${process.env.VUE_APP_HOST}/api/dashboard/product/${this.productData._id}/update`, data)
                    .then(response => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });
                    })
                    .catch( (err) => {
                        let message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información del activo.';
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                    });
            }
        }
    }

</script>

<style>

	.vue-dropzone {
		border-radius: 15px !important;
		border-style: dotted !important;
		border: 2px solid #3b4253 !important;
	}
	
	.dropzone {
		background: transparent !important;
	}
	
	.dropzone .dz-preview .dz-image {
		border-radius: 10px !important; 
	}
	.dropzone .dz-preview.dz-image-preview{
		background: transparent !important;
	}
	
	.vue-dropzone>.dz-preview .dz-details{
		background: transparent !important;
	}
	.vue-dropzone>.dz-preview .dz-remove {
		margin-left: 22px !important;
	}
    .ql-container {
		min-height: 200px;
	}

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
    @import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/quill.scss';
</style>